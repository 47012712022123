<template>
  <CCard>
    <CCardHeader>
      <strong>{{ this.$lang.dashboard.header.recent_resource_report }}</strong>
      <span style="float: right;">
        <CBadge color="success">{{ count }}</CBadge>
      </span>
    </CCardHeader>
    <CCardBody>
      <CDataTable class="mb-0 table-outline" hover :items="tableItems" :fields="tableFields"
                  @row-clicked="rowClickHandler" head-color="light" no-sorting>
        <td slot="firstName" slot-scope="{ item }">
          <span :title="item.firstName">{{ trimfunction(item.firstName, 15) }}</span>
        </td>
        <td slot="username" slot-scope="{ item }">
          <span :title="item.username">{{ trimfunction(item.username, 15) }}</span>
        </td>
        <td slot="description" slot-scope="{ item }">
          <span :title="item.description">{{ trimfunction(item.description, 15) }}</span>
        </td>
        <td slot="reason" slot-scope="{ item }">
          <span :title="item.reason">{{ trimfunction(item.reason, 15) }}</span>
        </td>
      </CDataTable>
      <CRow v-if="showProfile" style="margin-top: 2px">
        <CCol class="col-12 text-right">
          <button title="View All Profile" v-on:click="viewResourceReports" class="btn btn-light btn-block mb-1">
            View All
          </button>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>
<script>
import Vue from "vue";
import {ServerTable} from "vue-tables-2";
import store from "../../store/store";
import {Mixin} from "/src/mixins/index.js";
import {dashboardrecentResourceReportList} from "../../store/url";

Vue.use(ServerTable, {}, false);
export default {
  name: "RecentResourceReport",
  mixins: [Mixin],
  data() {
    return {
      self: this,
      count: 0,
      showProfile: false,
      moduleDashboardrecentResourceReportList: dashboardrecentResourceReportList,
      tableItems: [],
      tableFields: [
        {key: "firstName", label: this.$lang.dashboard.recentResourceReport.firstName},
        {key: "username", label: this.$lang.dashboard.recentResourceReport.username},
        {key: "description", label: this.$lang.dashboard.recentResourceReport.description},
        {key: "reason", label: this.$lang.dashboard.recentResourceReport.reason},
      ],
    };
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false);
    axios.get(this.listUrlApi(this.moduleDashboardrecentResourceReportList))
        .then((response) => {
          self.tableItems = response.data.data;
          self.count = response.data.count;
          if (response.data.data.length > 0) {
            self.showProfile = true;
          }
        });
  },
  methods: {
    rowClickHandler(item) {
      this.$router.push({path: `resource/view/${item.resourceId}`});
    },
    viewResourceReports() {
      this.$router.push({path: `resource`});
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
