<template>
  <CCard>
    <CCardHeader>
      <strong>{{ this.$lang.dashboard.header.recent_resource }}</strong>
      <span style="float: right;">
        <CBadge color="success">{{ count }}</CBadge>
      </span>
    </CCardHeader>
    <CCardBody>
      <CDataTable class="mb-0 table-outline" hover :items="tableItems" :fields="tableFields"
                  @row-clicked="rowClickHandler" head-color="light" no-sorting>
        <td slot="avatar" class="text-center" slot-scope="{ item }">
          <div class="c-avatar">
            <img :src="item.avatar" class="c-avatar-img" alt=""/>
          </div>
        </td>
        <td slot="detail" slot-scope="{ item }">
          <div>{{ item.profilerName }}</div>
        </td>
        <td slot="firstName" slot-scope="{ item }">
          <span :title="item.firstName">{{ trimfunction(item.firstName, 15) }}</span>
        </td>
        <td slot="title" slot-scope="{ item }">
          <span :title="item.title">{{ trimfunction(item.title, 15) }}</span>
        </td>
      </CDataTable>
      <CRow v-if="showProfile" style="margin-top: 2px">
        <CCol class="col-12 text-right">
          <button title="View All Profile" v-on:click="viewResources" class="btn btn-light btn-block mb-1">
            View All
          </button>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>
<script>
import Vue from "vue";
import {ServerTable} from "vue-tables-2";
import store from "../../store/store";
import {Mixin} from "/src/mixins/index.js";
import {dashboardrecentResourceList} from "../../store/url";

Vue.use(ServerTable, {}, false);
export default {
  name: "RecentResources",
  mixins: [Mixin],
  data() {
    return {
      self: this,
      count: 0,
      showProfile: false,
      moduleDashboardrecentResourceList: dashboardrecentResourceList,
      tableItems: [],
      tableFields: [
        {key: "avatar", label: "", _classes: "text-center"},
        {key: "detail", label: this.$lang.dashboard.recentResource.detail},
        {key: "title", label: this.$lang.dashboard.recentResource.title},
        {key: "firstName", label: this.$lang.dashboard.recentResource.firstName},
      ],
    };
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    axios.get(this.listUrlApi(this.moduleDashboardrecentResourceList))
        .then((response) => {
          self.tableItems = response.data.data;
          self.count = response.data.count;
          if (response.data.data.length > 0) {
            self.showProfile = true;
          }
        });
  },
  methods: {
    rowClickHandler(item) {
      this.$router.push({path: `resource/view/${item.id}`});
    },
    viewResources() {
      this.$router.push({path: `resource`});
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
