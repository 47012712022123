<template>
  <CCard>
    <CCardHeader>
      <strong>{{ this.$lang.dashboard.header.recent_group_post }}</strong>
      <span style="float: right;">
        <CBadge color="success">{{ count }}</CBadge>
      </span>
    </CCardHeader>
    <CCardBody>
      <CDataTable class="mb-0 table-outline" hover :items="tableItems" :fields="tableFields"
                  @row-clicked="rowClickHandler" head-color="light" no-sorting>
        <td slot="category" slot-scope="{ item }">
          <span :title="item.category">{{ trimfunction(item.category, 15) }}</span>
        </td>
        <td slot="message" slot-scope="{ item }">
          <span :title="item.message">{{ trimfunction(item.message, 15) }}</span>
        </td>
        <td slot="type" slot-scope="{ item }">
          <span :title="item.type">{{ trimfunction(item.type, 15) }}</span>
        </td>
        <td slot="name" slot-scope="{ item }">
          <span :title="item.name">{{ trimfunction(item.name, 15) }}</span>
        </td>
      </CDataTable>
      <CRow v-if="showProfile" style="margin-top: 2px">
        <CCol class="col-12 text-right">
          <button title="View All Profile" v-on:click="viewGroupPost" class="btn btn-light btn-block mb-1">
            View All
          </button>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>
<script>
import Vue from "vue";
import {ServerTable} from "vue-tables-2";
import store from "../../store/store";
import {Mixin} from "/src/mixins/index.js";
import {dashboardrecentGroupPostReportList} from "../../store/url";

Vue.use(ServerTable, {}, false);
export default {
  name: "RecentGroupPost",
  mixins: [Mixin],
  data() {
    return {
      self: this,
      count: 0,
      showProfile: false,
      moduleDashboardrecentGroupPostList: dashboardrecentGroupPostReportList,
      tableItems: [],
      tableFields: [
        {key: "category", label: this.$lang.dashboard.recentGroupPost.category},
        {key: "message", label: this.$lang.dashboard.recentGroupPost.message},
        {key: "type", label: this.$lang.dashboard.recentGroupPost.type},
        {key: "name", label: this.$lang.dashboard.recentGroupPost.name},
      ],
    };
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false);
    axios.get(this.listUrlApi(this.moduleDashboardrecentGroupPostList))
        .then((response) => {
          self.tableItems = response.data.data;
          self.count = response.data.count;
          if (response.data.data.length > 0) {
            self.showProfile = true;
          }
        });
  },
  methods: {
    rowClickHandler(item) {
      this.$router.push({path: `groups/detail/${item.groupId}`});
    },
    viewGroupPost() {
      this.$router.push({path: `groups`});
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
