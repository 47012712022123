<template>
  <CCard>
    <CCardHeader>
      <strong>Resource Chart</strong>
    </CCardHeader>
    <div id="chart">
      <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
  </CCard>
</template>
<script>
import Vue from "vue";
import {ServerTable} from "vue-tables-2";
import store from "../../store/store";
import {Mixin} from "/src/mixins/index.js";
import apexchart from "vue-apexcharts";

Vue.use(ServerTable, {}, false);
export default {
  name: "ResourceChart",
  mixins: [Mixin],
  components: {
    apexchart,
  },
  data() {
    return {
      self: this,
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nev', 'Dec'],
        },
        yaxis: {
          title: {
            text: 'No.'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " No."
            }
          }
        }
      },
    };
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    axios.get('/dashboard/resource/chart')
        .then((response) => {
          self.series = response.data;
        });
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
