var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v(_vm._s(this.$lang.dashboard.header.recent_resource))]),_c('span',{staticStyle:{"float":"right"}},[_c('CBadge',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.count))])],1)]),_c('CCardBody',[_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","items":_vm.tableItems,"fields":_vm.tableFields,"head-color":"light","no-sorting":""},on:{"row-clicked":_vm.rowClickHandler},scopedSlots:_vm._u([{key:"avatar",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"c-avatar"},[_c('img',{staticClass:"c-avatar-img",attrs:{"src":item.avatar,"alt":""}})])])}},{key:"detail",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',[_vm._v(_vm._s(item.profilerName))])])}},{key:"firstName",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{attrs:{"title":item.firstName}},[_vm._v(_vm._s(_vm.trimfunction(item.firstName, 15)))])])}},{key:"title",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{attrs:{"title":item.title}},[_vm._v(_vm._s(_vm.trimfunction(item.title, 15)))])])}}])}),(_vm.showProfile)?_c('CRow',{staticStyle:{"margin-top":"2px"}},[_c('CCol',{staticClass:"col-12 text-right"},[_c('button',{staticClass:"btn btn-light btn-block mb-1",attrs:{"title":"View All Profile"},on:{"click":_vm.viewResources}},[_vm._v(" View All ")])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }